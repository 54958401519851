<template>
  <div class="main-content">
    <avue-form ref="form" :value="form" :option="formOption">
      <!-- <template slot="logo">
        <FormImgUpload
          :url="form.logo"
          @upload="upload"
          :limit="1"
          :maxSize="20"
          :accept="'.png,.jpg,.jpeg,.svg,.tiff'"
          :multiple="true"
        />
        <div style="color: #c0c1c3">
          建议尺寸：300px*64px，图片格式：'.png,.jpg,.jpeg,.svg,.tiff'
        </div>
      </template> -->
      <!-- <template slot="email">
        <el-input
          v-model="form.email"
          maxlength="50"
          placeholder="请输入"
          style="width: 350px"
          onkeyup="this.value=this.value"
          type="text"
        />
      </template> -->
      <!-- <template slot="telephone">
        <el-input
          v-model="form.telephone"
          maxlength="20"
          placeholder="请输入"
          style="width: 350px"
          onkeyup="this.value=this.value"
          type="text"
        />
      </template> -->
      <!-- <template slot="address">
        <el-input
          v-model="form.address"
          maxlength="50"
          placeholder="请输入"
          style="width: 350px"
          onkeyup="this.value=this.value"
          type="text"
        />
      </template> -->
      <template slot="ak">
        <el-input
          v-model="form.ak"
          maxlength="100"
          placeholder="请输入"
          style="width: 350px"
          onkeyup="this.value=this.value"
          type="text"
        />
      </template>
      <template slot="sk">
        <el-input
          v-model="form.sk"
          maxlength="100"
          placeholder="请输入"
          style="width: 350px"
          onkeyup="this.value=this.value"
          type="text"
        />
      </template>
      <template slot="menuForm">
        <!-- <el-button @click="handleClose">取消</el-button> -->
        <el-button type="primary" :loading="showLoading" @click="submit(form)"
          >确定</el-button
        >
      </template>
    </avue-form>
  </div>
</template>

<script>
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { basicUpdate, basicView } from "@/api/system/basic";
import { enterChineseEnglish } from "@/utils/formRules";
import { systemParamView, systemParamUpdate } from "@/api/system/param";
import { emailRegex, telephoneRule } from "@/utils/formRules";
export default {
  name: "createThree",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      form: {
        // telephone: "",
        // logo: [],
        // email: "",
        // address: "",
        ak: "",
        sk: "",
      },
      showLoading: false,
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 150,
        column: [
          // {
          //   label: "网站logo",
          //   prop: "logo",
          //   type: "text",
          //   maxlength: 50,
          //   span: 24,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请上传",
          //       trigger: "blur",
          //     },
          //     {
          //       validator: (rule, value, callback) => {
          //         if (value.length === 0) {
          //           callback(new Error("请上传"));
          //         } else {
          //           callback();
          //         }
          //       },
          //     },
          //   ],
          // },

          // {
          //   label: "电子邮箱",
          //   prop: "email",
          //   type: "text",
          //   maxlength: 50,
          //   span: 24,
          //   placeholder: "请输入",
          //   rules: [
          //     {
          //       required: true,
          //       message: "请输入域名",
          //       trigger: "blur",
          //     },
          //     ...emailRegex,
          //   ],
          // },
          // {
          //   label: "电话",
          //   prop: "telephone",
          //   type: "text",
          //   maxlength: 50,
          //   span: 24,
          //   placeholder: "请输入",
          //   rules: [
          //     {
          //       required: true,
          //       message: "请输入备案号",
          //       trigger: "blur",
          //     },
          //     ...telephoneRule,
          //   ],
          // },
          // {
          //   label: "地址",
          //   prop: "address",
          //   type: "text",
          //   maxlength: 50,
          //   span: 24,
          //   placeholder: "请输入",
          //   rules: [
          //     {
          //       required: true,
          //       message: "请输入备案号",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          {
            label: "AK",
            prop: "ak",
            type: "text",
            maxlength: 50,
            span: 24,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入AK",
                trigger: "blur",
              },
            ],
          },
          {
            label: "SK",
            prop: "sk",
            type: "text",
            maxlength: 50,
            span: 24,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入SK",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    //初始化
    onLoad() {
      this.showLoading = true;
      systemParamView().then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.form = {
            ...res.data,
            // logo: res.data.logo.split(","),
          };
        }
      });
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.logo = [...e];
      } else {
        this.form.logo = [...this.form.logo, e];
      }
    },
    //小图标
    uploadDefaultDeviceImage(e) {
      if (Array.isArray(e)) {
        this.form.miniLogo = [...e];
      } else {
        this.form.miniLogo = [...this.form.miniLogo, e];
      }
    },

    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          const res = {
            ...form,
            // logo: form.logo.join(","),
          };
          this.showLoading = true;
          systemParamUpdate({ ...res })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.dialogVisible = false;
                this.onLoad();
                this.showLoading = false;
              } else {
                // this.$message.error(res.msg);
                this.showLoading = false;
                done();
              }
            })
            .catch(() => {
              this.showLoading = false;
            });
          // done()
        }
      });
    },
  },
};
</script>

<style scoped></style>
